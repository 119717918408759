<template>
  <v-container>
    <v-layout>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary"
                       dark
                       flat>
              <v-card-title>
                Edit tenant - {{ editedTenant.name }}
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-row class="my-0">
                  <v-col cols="6">
                    <v-text-field outlined
                                  label="Name"
                                  name="name"
                                  autofocus
                                  v-model="editedTenant.name" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field outlined
                                  label="Currency"
                                  name="currency"
                                  v-model="editedTenant.currency" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field outlined
                                  label="Locale"
                                  name="locale"
                                  v-model="editedTenant.locale" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field outlined
                                  label="Web URL"
                                  name="webUrl"
                                  v-model="editedTenant.webUrl" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-switch class="ma-0 ml-3"
                              outlined
                              label="Enable sending emails"
                              name="enableSendingEmails"
                              v-model="editedTenant.enableSendingEmails" />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text
                     large
                     @click="resetTenant">
                Reset
              </v-btn>
              <v-btn text
                     large
                     color="primary"
                     :disabled="saveDisabled"
                     @click="updateTenant">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import _isEqual from 'lodash/isEqual';

export default {
  name: 'Tenant',
  data: () => ({
    editedTenant: {},
  }),
  computed: {
    ...mapGetters(['tenant']),
    saveDisabled() {
      return !Object.keys(this.editedTenant).length || _isEqual(this.editedTenant, this.tenant);
    },
  },
  methods: {
    resetTenant() {
      this.editedTenant = { ...this.tenant };
    },
    getTenant() {
      this.$store.dispatch('loadTenant', this.$route.params.uuid)
        .then(() => this.resetTenant());
    },
    updateTenant() {
      this.$store.dispatch('updateTenant', this.editedTenant)
        .then(() => {
          this.getTenant();
          this.$router.push({ name: 'home' });
        });
    },
  },
  created() {
    this.getTenant();
  },
};
</script>

<style lang="scss">
</style>
